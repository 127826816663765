/* eslint-disable */
export default {
  development: {
    API: "https://f2f-meetings-api.app-o-ran.org/api/", // API: "https://localhost:7245/api/" http://localhost:5245/api/ https://f2fmeeting-dev.azurewebsites.net/api/
    Source: "http://localhost:5237/api/",
  },
  production: {
    API: "https://f2f-meetings-api.app-o-ran.org/api/", //  https://f2f-meeting-prod.azurewebsites.net/api/ https://f2fmeeting-dev.azurewebsites.net/api/
    Source: "https://f2f-meetings-admin.app-o-ran.org/", // https://polite-forest-010c92603.2.azurestaticapps.net/  https://white-hill-054ae5503.2.azurestaticapps.net/
  },
};
