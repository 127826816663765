/* eslint-disable */
import { relativeTimeThreshold } from "moment";
import Setting from "../../setting.js";
import { useAppStore } from "../stores/main";

export default {
  name: "SchedulesView",
  components: {},
  data: () => ({
    store: useAppStore(),
  }),
  async mounted() {
    // console.warn(this.$route);

    if (!this.store.getAuth) {
      this.$router.push("/");
    }
  },
  methods: {},
};
