export default {
  name: "SmartTable",
  props: {
    headers: Array,
    items: Array,
    editedItem: Object,
    loading: Boolean,
  },
  data() {
    return {
      search: "",
      dialog: false,
      dialogRemove: false,
      editedIndex: -1,

      editedItemInner: JSON.parse(JSON.stringify(this.editedItem)),
      defaultItemInner: JSON.parse(JSON.stringify(this.editedItem)),

      itemToRemove: null,
      valid: false,

      statuses: [
        {
          id: 0,
          name: "On site",
        },
        {
          id: 1,
          name: "Remote",
        },
        {
          id: 2,
          name: "Canceled",
        },
      ],

      menu: false,
      modal: false,
    };
  },

  computed: {
    getLanguage() {
      // console.warn(this.editedItemInner.data.country);
      return this.editedItemInner.data.country;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getStatus(status) {
      let result = "";
      switch (status) {
        case 0:
          result = "On site";
          break;
        case 1:
          result = "Remote";
          break;
        case 2:
          result = "Canceled";
          break;
      }
      return result;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItemInner = Object.assign({}, item);

      /*
      console.warn({
        editItem: item,
        editedIndex: this.editedIndex,
        editedItemInner: this.editedItemInner,
      });
      */

      this.dialog = true;
    },
    removeItem(item) {
      // console.warn(item);

      this.itemToRemove = item;

      this.dialogRemove = true;
    },
    onRemoveItem() {
      this.$emit("onRemove", {
        data: this.itemToRemove.data,
      });

      this.closeRemove();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItemInner = Object.assign({}, this.defaultItemInner);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.$emit("onUpdate", {
          data: this.editedItemInner,
        });

        Object.assign(this.items[this.editedIndex], this.editedItemInner);
      } else {
        this.$emit("onInsert", {
          data: this.editedItemInner,
        });

        // this.items.push(this.editedItem);
      }
      this.close();
    },
    onSelect({ name, iso2, dialCode }) {
      console.warn(name, iso2, dialCode);

      this.editedItemInner.data.country = iso2;
      this.editedItemInner.data.code = dialCode;
    },
    closeRemove() {
      this.dialogRemove = false;
    },
  },
};
