import { useAppStore } from "../stores/main";

export default {
  name: "HomeView",
  components: {},
  data: () => ({
    store: useAppStore(),
    password: null,
    secret: "P8NAtTc",
    isPassValid: true,

    rulesPass: [
      (value) => {
        if (value && value == this.secret) return true;

        return "Password is not valid.";
      },
    ],
  }),
  async mounted() {},
  methods: {
    onLogin() {
      /*
      console.warn({
        password: this.password,
        secret: this.secret,
      });
      */

      this.isPassValid = true;

      if (this.password == this.secret) {
        this.store.saveAuth(true);
        // console.warn(this.store.getAuth);
        // this.$router.push("/login");
      } else {
        this.isPassValid = false;
      }
    },
    onMeetings() {
      this.$router.push("/meetings");
    },
    onNgrgForm() {
      this.$router.push("/ngrg");
    },
    onNgrgSurveyForm() {
      this.$router.push("/ngrg-survey");
    },
    onCVDForm() {
      this.$router.push("/cvd-form");
    },
  },
};
