/* eslint-disable */
import { relativeTimeThreshold } from "moment";
import Setting from "../../setting.js";
import { useAppStore } from "../stores/main";

export default {
  name: "MeetingsView",
  components: {},
  data: () => ({
    store: useAppStore(),
    loading: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
    ],
    meetingsHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
        class: "name",
      },
      {
        text: "Actions",
        align: "start",
        sortable: false,
        value: "actions",
        class: "actions",
      },
    ],
    meetingsItems: [],
  }),
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },
    async getData() {
      this.loading = true;
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Admin/GetAllMeeting",
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    onMeeting(event) {
      this.$router.push({ name: "meeting", params: { id: event.id } });
    },
    onUsers(event) {
      this.$router.push({ name: "users", params: { id: event.id } });
    },
  },
  async created() {
    let mettings = await this.getData();

    this.loading = false;

    // console.warn(mettings.data.data);

    this.meetingsItems = mettings.data.data;
  },
  async mounted() {
    if (!this.store.getAuth) {
      this.$router.push("/");
    }
  },
};
