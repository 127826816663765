import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import UsersView from "../views/UsersView.vue";
import MeetingsView from "../views/MeetingsView.vue";
import MeetingView from "../views/MeetingView.vue";
import SchedulesView from "../views/SchedulesView.vue";
import ScheduleView from "../views/ScheduleView.vue";
import NgrgForm from "../views/NgrgForm.vue";
import NgrgSurveyForm from "../views/NgrgSurveyForm.vue";
import CvdForm from "../views/CvdForm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/users/:id",
    name: "users",
    component: UsersView,
  },
  {
    path: "/meetings",
    name: "meetings",
    component: MeetingsView,
  },
  {
    path: "/meeting/:id",
    name: "meeting",
    component: MeetingView,
  },
  {
    path: "/schedules",
    name: "schedules",
    component: SchedulesView,
  },
  {
    path: "/schedule/:id",
    name: "schedule",
    component: ScheduleView,
  },
  {
    path: "/ngrg",
    name: "ngrg",
    component: NgrgForm,
  },
  {
    path: "/ngrg-survey",
    name: "ngrgsurvey",
    component: NgrgSurveyForm,
  },
  {
    path: "/cvd-form",
    name: "cvdform",
    component: CvdForm,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
