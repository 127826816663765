import Setting from "../../setting.js";
import NgrgTable from "@/components/NgrgTable.vue";
import ExcelExport from "@/components/ExcelExport.vue";
import { useAppStore } from "../stores/main";

export default {
  name: "NgrgSurveyForm",

  components: {
    NgrgTable,
    ExcelExport,
  },
  data: () => ({
    store: useAppStore(),
    loading: false,
    items: [],
    exportItems: [],

    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },

      {
        text: "NGRG Survey Form",
        disabled: false,
      },
    ],
    headers: [
      {
        text: "Email",
        align: "start",
        sortable: true,
        value: "data.email",
      },
      {
        text: "Create Date",
        align: "end",
        sortable: true,
        value: "createdDate",
      },
      {
        text: "Name",
        align: "end",
        sortable: true,
        value: "data.name",
      },
      {
        text: "Institution",
        align: "end",
        sortable: true,
        value: "data.institution",
      },
      {
        text: "Architecture",
        align: "end",
        sortable: true,
        value: "data.architecture",
      },
      {
        text: "Technologies",
        align: "end",
        sortable: true,
        value: "data.technologies",
      },
      {
        text: "Testbed description",
        align: "end",
        sortable: true,
        value: "data.testbeddescription",
      },
      {
        text: "Testbed name",
        align: "end",
        sortable: true,
        value: "data.testbedname",
      },
      {
        text: "Testbed url",
        align: "end",
        sortable: true,
        value: "data.testbedurl",
      },
      {
        text: "Use cases",
        align: "end",
        sortable: true,
        value: "data.usecases",
      },

      {
        text: "Architecture Elements FHGW Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_FHGW_Current",
      },

      {
        text: "Architecture Elements FHGW Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_FHGW_Planned",
      },

      {
        text: "Architecture Elements GM Clock Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_GM_Clock_Current",
      },

      {
        text: "Architecture Elements GM Clock Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_GM_Clock_Planned",
      },

      {
        text: "Architecture Elements O-CU Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_O_CU_Current",
      },

      {
        text: "Architecture Elements O-CU Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_O_CU_Planned",
      },

      {
        text: "Architecture Elements O-DU Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_O_DU_Current",
      },

      {
        text: "Architecture Elements O-DU Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_O_DU_Planned",
      },

      {
        text: "ArchitectureElements O-RU Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_O_RU_Current",
      },

      {
        text: "Architecture Elements O-RU Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_O_RU_Planned",
      },

      {
        text: "Architecture Elements SMO Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_SMO_Current",
      },

      {
        text: "Architecture Elements SMO Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_SMO_Planned",
      },

      {
        text: "Architecture Elements near RT_RIC Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_near_RT_RIC_Current",
      },

      {
        text: "Architecture Elements near RT_RIC Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_near_RT_RIC_Planned",
      },

      {
        text: "Architecture Elements non RT_RIC Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_non_RT_RIC_Current",
      },

      {
        text: "Architecture Elements non RT_RIC Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_non_RT_RIC_Planned",
      },

      {
        text: "Architecture Elements rApps Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_rApps_Current",
      },

      {
        text: "Architecture Elements rApps Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_rApps_Planned",
      },

      {
        text: "Architecture Elements xApps Current",
        align: "end",
        sortable: true,
        value: "data.architectureElements_xApps_Current",
      },

      {
        text: "Architecture Elements xApps Planned",
        align: "end",
        sortable: true,
        value: "data.architectureElements_xApps_Planned",
      },

      {
        text: "Interfaces Testbed Support A1 Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_A1_Available",
      },

      {
        text: "Interfaces Testbed Support A1 Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_A1_Planned",
      },

      {
        text: "Interfaces Testbed Support E2 Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_E2_Available",
      },

      {
        text: "Interfaces Testbed Support E2 Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_E2_Planned",
      },

      {
        text: "Interfaces Testbed Support F1 Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_F1_Available",
      },

      {
        text: "Interfaces Testbed Support F1 Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_F1_Planned",
      },

      {
        text: "Interfaces Testbed Support Fronthaul Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_Fronthaul_Available",
      },

      {
        text: "Interfaces Testbed Support Fronthaul Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_Fronthaul_Planned",
      },

      {
        text: "Interfaces Testbed Support O1 Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_O1_Available",
      },

      {
        text: "Interfaces Testbed Support O1 Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_O1_Planned",
      },

      {
        text: "Interfaces Testbed Support O2 Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_O2_Available",
      },

      {
        text: "Interfaces Testbed Support O2 Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_O2_Planned",
      },

      {
        text: "Interfaces Testbed Support X2 Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_X2_Available",
      },

      {
        text: "Interfaces Testbed Support X2 Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_X2_Planned",
      },

      {
        text: "Interfaces Testbed Support Xn Available",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_Xn_Available",
      },

      {
        text: "Interfaces Testbed Support Xn Planned",
        align: "end",
        sortable: true,
        value: "data.interfacesTestbedSupport_Xn_Planned",
      },

      {
        text: "Platform Capable Continuous Testing",
        align: "end",
        sortable: true,
        value: "data.platformCapableContinuousTesting",
      },

      {
        text: "Platform Capable Functional Tests Subsystems",
        align: "end",
        sortable: true,
        value: "data.platformCapableFunctionalTestsSubsystems",
      },

      {
        text: "Platform Capable Instrumentations Testing",
        align: "end",
        sortable: true,
        value: "data.platformCapableInstrumentationsTesting",
      },
      {
        text: "Platform Capable Performance Tests EndToEnd Subsystems",
        align: "end",
        sortable: true,
        value: "data.platformCapablePerformanceTestsEndToEndSubsystems",
      },

      {
        text: "Platform Capable Performance Tests Subsystems",
        align: "end",
        sortable: true,
        value: "data.platformCapablePerformanceTestsSubsystems",
      },
      {
        text: "Platform Environment CU applications Current",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_CU_applications_Current",
      },

      {
        text: "Platform Environment CU applications Planned",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_CU_applications_Planned",
      },

      {
        text: "Platform Environment DU applications Current",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_DU_applications_Current",
      },

      {
        text: "Platform Environment DU applications Planned",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_DU_applications_Planned",
      },

      {
        text: "Platform Environment OA&M applications Current",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_OA_And_M_applications_Current",
      },

      {
        text: "Platform Environment OA&M applications Planned",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_OA_And_M_applications_Planned",
      },

      {
        text: "Platform Environment rAPPs Current",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_rAPPs_Current",
      },

      {
        text: "Platform Environment rAPPs Planned",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_rAPPs_Planned",
      },

      {
        text: "Platform Environment xAPPs Current",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_xAPPs_Current",
      },
      {
        text: "Platform Environment xAPPs Planned",
        align: "end",
        sortable: true,
        value: "data.platformEnvironment_xAPPs_Planned",
      },
      {
        text: "Platform Capable Demonstration",
        align: "end",
        sortable: true,
        value: "data.platformCapableDemonstration",
      },
      {
        text: "Platform Capable Research",
        align: "end",
        sortable: true,
        value: "data.platformCapableResearch",
      },
      {
        text: "Platform Open Interface",
        align: "end",
        sortable: true,
        value: "data.platformOpenInterface",
      },
    ],
    fields: {
      Email: "email",
      CreatedDate: "createdDate",
      Name: "name",
      Institution: "institution",
      Architecture: "architecture",
      Technologies: "technologies",
      TestbedDescription: "testbeddescription",
      TestbedName: "testbedname",
      TestbedUrl: "testbedurl",
      UseCases: "usecases",

      "Architecture Elements FHGW Current": "architectureElements_FHGW_Current",
      "Architecture Elements FHGW Planned": "architectureElements_FHGW_Planned",
      "Architecture Elements GM Clock Current":
        "architectureElements_GM_Clock_Current",
      "Architecture Elements GM Clock_Planned":
        "architectureElements_GM_Clock_Planned",
      "Architecture Elements O-CU Current": "architectureElements_O_CU_Current",
      "Architecture Elements O-CU Planned": "architectureElements_O_CU_Planned",
      "Architecture Elements O-DU Current": "architectureElements_O_DU_Current",
      "Architecture Elements O-DU Planned": "architectureElements_O_DU_Planned",
      "Architecture Elements O-RU Current": "architectureElements_O_RU_Current",
      "Architecture Elements O-RU Planned": "architectureElements_O_RU_Planned",
      "Architecture Elements SMO Current": "architectureElements_SMO_Current",
      "Architecture Elements SMO Planned": "architectureElements_SMO_Planned",
      "Architecture Elements near RT RIC Current":
        "architectureElements_near_RT_RIC_Current",
      "Architecture Elements near RT_RIC Planned":
        "architectureElements_near_RT_RIC_Planned",
      "Architecture Elements non RT_RIC Current":
        "architectureElements_non_RT_RIC_Current",
      "Architecture Elements non RT RIC Planned":
        "architectureElements_non_RT_RIC_Planned",
      "Architecture Elements rApps Current":
        "architectureElements_rApps_Current",
      "Architecture Elements rApps Planned":
        "architectureElements_rApps_Planned",
      "Architecture Elements xApps Current":
        "architectureElements_xApps_Current",
      "Architecture Elements xApps Planned":
        "architectureElements_xApps_Planned",
      "Interfaces Testbed Support A1 Available":
        "interfacesTestbedSupport_A1_Available",
      "Interfaces Testbed Support A1 Planned":
        "interfacesTestbedSupport_A1_Planned",
      "Interfaces Testbed Support E2 Available":
        "interfacesTestbedSupport_E2_Available",
      "Interfaces Testbed Support E2 Planned":
        "interfacesTestbedSupport_E2_Planned",
      "Interfaces Testbed Support F1 Available":
        "interfacesTestbedSupport_F1_Available",
      "Interfaces Testbed Support F1 Planned":
        "interfacesTestbedSupport_F1_Planned",
      "Interfaces Testbed Support Fronthaul Available":
        "interfacesTestbedSupport_Fronthaul_Available",
      "Interfaces Testbed Support Fronthaul Planned":
        "interfacesTestbedSupport_Fronthaul_Planned",
      "Interfaces Testbed Support O1 Available":
        "interfacesTestbedSupport_O1_Available",
      "Interfaces Testbed Support O1 Planned":
        "interfacesTestbedSupport_O1_Planned",
      "Interfaces Testbed Support O2 Available":
        "interfacesTestbedSupport_O2_Available",
      "Interfaces Testbed Support O2 Planned":
        "interfacesTestbedSupport_O2_Planned",
      "Interfaces Testbed Support X2 Available":
        "interfacesTestbedSupport_X2_Available",
      "Interfaces Testbed Support X2 Planned":
        "interfacesTestbedSupport_X2_Planned",
      "Interfaces Testbed Support Xn Available":
        "interfacesTestbedSupport_Xn_Available",
      "Interfaces Testbed Support Xn Planned":
        "interfacesTestbedSupport_Xn_Planned",
      "Platform Capable Continuous Testing": "platformCapableContinuousTesting",
      "Platform Capable Functional Tests Subsystems":
        "platformCapableFunctionalTestsSubsystems",
      "Platform Capable Instrumentations Testing":
        "platformCapableInstrumentationsTesting",
      "Platform Capable Performance Tests EndToEnd Subsystems":
        "platformCapablePerformanceTestsEndToEndSubsystems",
      "Platform Capable Performance Tests Subsystems":
        "platformCapablePerformanceTestsSubsystems",
      "Platform Environment CU applications Current":
        "platformEnvironment_CU_applications_Current",
      "Platform Environment CU applications Planned":
        "platformEnvironment_CU_applications_Planned",
      "Platform Environment DU applications Current":
        "platformEnvironment_DU_applications_Current",
      "Platform Environment DU applications Planned":
        "platformEnvironment_DU_applications_Planned",
      "Platform Environment OA And M applications Current":
        "platformEnvironment_OA_And_M_applications_Current",
      "Platform Environment OA And M applications Planned":
        "platformEnvironment_OA_And_M_applications_Planned",
      "Platform Environment rAPPs Current": "platformEnvironment_rAPPs_Curren",
      "Platform Environment rAPPs Planned": "platformEnvironment_rAPPs_Planned",
      "Platform Environment xAPPs Current": "platformEnvironment_xAPPs_Current",
      "Platform Environment xAPPs Planned": "platformEnvironment_xAPPs_Planned",

      "Platform Capable Demonstration": "platformCapableDemonstration",
      "Platform Capable Research": "platformCapableResearch",
      "Platform Open Interface": "platformOpenInterface",
    },
  }),
  async mounted() {
    if (!this.store.getAuth) {
      this.$router.push("/");
    }
  },
  async created() {
    this.loading = true;
    const result = await this.getData();

    this.items = result.data.items;

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      this.exportItems.push({
        email: item.data.email,
        name: item.data.name
          ? item.data.name.replaceAll(",", ";").replaceAll(/\n/g, "; ").trim()
          : "",
        institution: item.data.institution
          ? item.data.institution
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        architecture: item.data.architecture
          ? item.data.architecture
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        technologies: item.data.technologies
          ? item.data.technologies
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        testbeddescription: item.data.testbeddescription
          ? item.data.testbeddescription
              .replaceAll(",", ";")
              .replaceAll(/\n/g, " ")
              .trim()
          : "",
        testbedname: item.data.testbedname
          ? item.data.testbedname
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        testbedurl: item.data.testbedurl
          ? item.data.testbedurl
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        usecases: item.data.usecases
          ? item.data.usecases
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",

        architectureElements_FHGW_Current:
          item.data.architectureElements_FHGW_Current,
        architectureElements_FHGW_Planned:
          item.data.architectureElements_FHGW_Planned,
        architectureElements_GM_Clock_Current:
          item.data.architectureElements_GM_Clock_Current,
        architectureElements_GM_Clock_Planned:
          item.data.architectureElements_GM_Clock_Planned,
        architectureElements_O_CU_Current:
          item.data.architectureElements_O_CU_Current,
        architectureElements_O_CU_Planned:
          item.data.architectureElements_O_CU_Planned,
        architectureElements_O_DU_Current:
          item.data.architectureElements_O_DU_Current,
        architectureElements_O_DU_Planned:
          item.data.architectureElements_O_DU_Planned,
        architectureElements_O_RU_Current:
          item.data.architectureElements_O_RU_Current,
        architectureElements_O_RU_Planned:
          item.data.architectureElements_O_RU_Planned,
        architectureElements_SMO_Current:
          item.data.architectureElements_SMO_Current,
        architectureElements_SMO_Planned:
          item.data.architectureElements_SMO_Planned,
        architectureElements_near_RT_RIC_Current:
          item.data.architectureElements_near_RT_RIC_Current,
        architectureElements_near_RT_RIC_Planned:
          item.data.architectureElements_near_RT_RIC_Planned,
        architectureElements_non_RT_RIC_Current:
          item.data.architectureElements_non_RT_RIC_Current,
        architectureElements_non_RT_RIC_Planned:
          item.data.architectureElements_non_RT_RIC_Planned,
        architectureElements_rApps_Current:
          item.data.architectureElements_rApps_Current,
        architectureElements_rApps_Planned:
          item.data.architectureElements_rApps_Planned,
        architectureElements_xApps_Current:
          item.data.architectureElements_xApps_Current,
        architectureElements_xApps_Planned:
          item.data.architectureElements_xApps_Planned,
        interfacesTestbedSupport_A1_Available:
          item.data.interfacesTestbedSupport_A1_Available,
        interfacesTestbedSupport_A1_Planned:
          item.data.interfacesTestbedSupport_A1_Planned,
        interfacesTestbedSupport_E2_Available:
          item.data.interfacesTestbedSupport_E2_Available,
        interfacesTestbedSupport_E2_Planned:
          item.data.interfacesTestbedSupport_E2_Planned,
        interfacesTestbedSupport_F1_Available:
          item.data.interfacesTestbedSupport_F1_Available,
        interfacesTestbedSupport_F1_Planned:
          item.data.interfacesTestbedSupport_F1_Planned,
        interfacesTestbedSupport_Fronthaul_Available:
          item.data.interfacesTestbedSupport_Fronthaul_Available,
        interfacesTestbedSupport_Fronthaul_Planned:
          item.data.interfacesTestbedSupport_Fronthaul_Planned,
        interfacesTestbedSupport_O1_Available:
          item.data.interfacesTestbedSupport_O1_Available,
        interfacesTestbedSupport_O1_Planned:
          item.data.interfacesTestbedSupport_O1_Planned,
        interfacesTestbedSupport_O2_Available:
          item.data.interfacesTestbedSupport_O2_Available,
        interfacesTestbedSupport_O2_Planned:
          item.data.interfacesTestbedSupport_O2_Planned,
        interfacesTestbedSupport_X2_Available:
          item.data.interfacesTestbedSupport_X2_Available,
        interfacesTestbedSupport_X2_Planned:
          item.data.interfacesTestbedSupport_X2_Planned,
        interfacesTestbedSupport_Xn_Available:
          item.data.interfacesTestbedSupport_Xn_Available,
        interfacesTestbedSupport_Xn_Planned:
          item.data.interfacesTestbedSupport_Xn_Planned,
        platformCapableContinuousTesting:
          item.data.platformCapableContinuousTesting,
        platformCapableFunctionalTestsSubsystems:
          item.data.platformCapableFunctionalTestsSubsystems,
        platformCapableInstrumentationsTesting:
          item.data.platformCapableInstrumentationsTesting,
        platformCapablePerformanceTestsEndToEndSubsystems:
          item.data.platformCapablePerformanceTestsEndToEndSubsystems,
        platformCapablePerformanceTestsSubsystems:
          item.data.platformCapablePerformanceTestsSubsystems,
        platformEnvironment_CU_applications_Current:
          item.data.platformEnvironment_CU_applications_Current,
        platformEnvironment_CU_applications_Planned:
          item.data.platformEnvironment_CU_applications_Planned,
        platformEnvironment_DU_applications_Current:
          item.data.platformEnvironment_DU_applications_Current,
        platformEnvironment_DU_applications_Planned:
          item.data.platformEnvironment_DU_applications_Planned,
        platformEnvironment_OA_And_M_applications_Current:
          item.data.platformEnvironment_OA_And_M_applications_Current,
        platformEnvironment_OA_And_M_applications_Planned:
          item.data.platformEnvironment_OA_And_M_applications_Planned,
        platformEnvironment_rAPPs_Current:
          item.data.platformEnvironment_rAPPs_Curren,
        platformEnvironment_rAPPs_Planned:
          item.data.platformEnvironment_rAPPs_Planned,
        platformEnvironment_xAPPs_Current:
          item.data.platformEnvironment_xAPPs_Current,
        platformEnvironment_xAPPs_Planned:
          item.data.platformEnvironment_xAPPs_Planned,

        // prettier-ignore
        platformCapableDemonstration: item.data.platformCapableDemonstration ? item.data.platformCapableDemonstration.join(
          "; "
        ).replaceAll(",", ";").replaceAll(/\n/g, '; ').trim() : "",
        platformCapableResearch: item.data.platformCapableResearch
          ? item.data.platformCapableResearch
              .join("; ")
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",
        platformOpenInterface: item.data.platformOpenInterface
          ? item.data.platformOpenInterface
              .join("; ")
              .replaceAll(",", ";")
              .replaceAll(/\n/g, "; ")
              .trim()
          : "",

        createdDate: item.createdDate,
        updatedDate: item.lastUpdateDate,
      });
    }

    /*
    console.warn({
      items: this.items,
      exportItems: this.exportItems,
    });
    */

    this.loading = false;
  },
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },
    async getData() {
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "NGRGFormSurveyContoller/GetAll",

        {
          headers: {
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    getDocumentName(id) {
      let item = this.documents.find((item) => item.id === id);
      return item ? item.title : "";
    },
    getScore(item, index) {
      switch (index) {
        case 1:
          return item.c1_score;
        case 2:
          return item.c2_score;
        case 3:
          return item.c3_score;
      }

      return "";
    },
    getComment(item, index) {
      switch (index) {
        case 1:
          return item.c1_comment;
        case 2:
          return item.c2_comment;
        case 3:
          return item.c3_comment;
      }

      return "";
    },
  },
};
