export default {
  name: "SmartTableRegistrations",
  props: {
    headers: Array,
    items: Array,
    editedItem: Object,
    loading: Boolean,
  },
  data() {
    return {
      search: "",
      dialog: false,

      statuses: [
        {
          id: 0,
          name: "On site",
        },
        {
          id: 1,
          name: "Remote",
        },
        {
          id: 2,
          name: "Canceled",
        },
      ],

      menu: false,
      modal: false,
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getStatus(status) {
      let result = "";
      switch (status) {
        case 0:
          result = "On site";
          break;
        case 1:
          result = "Remote";
          break;
        case 2:
          result = "Canceled";
          break;
      }
      return result;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItemInner = Object.assign({}, item);

      /*
      console.warn({
        editItem: item,
        editedIndex: this.editedIndex,
        editedItemInner: this.editedItemInner,
      });
      */

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItemInner = Object.assign({}, this.defaultItemInner);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.$emit("onUpdate", {
          data: this.editedItemInner,
        });

        Object.assign(this.items[this.editedIndex], this.editedItemInner);
      } else {
        this.$emit("onInsert", {
          data: this.editedItemInner,
        });

        // this.items.push(this.editedItem);
      }
      this.close();
    },
  },
};
