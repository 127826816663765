import Setting from "../../setting.js";
import NgrgTable from "@/components/NgrgTable.vue";
import ExcelExport from "@/components/ExcelExport.vue";
import { useAppStore } from "../stores/main";

export default {
  name: "NgrgForm",

  components: {
    NgrgTable,
    ExcelExport,
  },
  data: () => ({
    store: useAppStore(),
    loading: false,
    items: [],
    exportItems: [],
    documents: [
      {
        id: 17,
        title: "no.17 - Proposal_v2_Quantum Walks Technologies",
      },
      { id: 16, title: "no.16 - Proposal_University of Delhi" },
      { id: 15, title: "no.15 - Proposal_Capgemini and King’s College" },
      { id: 14, title: "no.14 - Proposal_EURECOM-OpenAirInterface" },
      { id: 13, title: "no.13 - Proposal_SEEDS Institute" },
      { id: 12, title: "no.12 - Proposal_CCI&VT" },
      { id: 11, title: "no.11 - Proposal_Shabodi" },
      { id: 10, title: "no.10 - Proposal_Tietoevry Corporation" },
      { id: 9, title: "no.9 - Proposal_IMEC" },
      { id: 8, title: "no.8 - Proposal_Technical University of Dresden" },
      { id: 7, title: "no.7 - Proposal_University of British Columbia" },
      { id: 6, title: "no.6 - Proposal_ONF&Rimedo Labs_CA&Poland" },
      { id: 5, title: "no.5 - Proposal_Wireless Int&Northeastern Unv." },
      { id: 4, title: "no.4 - Proposal_Tiami LLc" },
      { id: 3, title: "no.3 - Proposal_University of Thessaly" },
      {
        id: 2,
        title: "no.2 - Proposal_Wireless Int&Northeastern Unv Boston",
      },
      { id: 1, title: "no.1 - Proposal_Sorbonne Université" },
    ],
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },

      {
        text: "NGRG Form",
        disabled: false,
      },
    ],
    headers: [
      {
        text: "Email",
        align: "start",
        sortable: true,
        value: "data.email",
      },
      {
        text: "Updated Date",
        align: "end",
        sortable: true,
        value: "lastUpdateDate",
      },
      {
        text: "Items",
        align: "start",
        sortable: false,
        value: "data.submits",
      },
    ],
    fields: {
      Email: "email",
      CreatedDate: "createdDate",
      UpdatedDate: "updatedDate",
      Document: "document",
      Type: "type",
      Score: "score",
      Comment: "comment",
    },
  }),
  async mounted() {
    /*
    this.exportItems = this.items.map((x) => {
      return {
        email: x.data.email,
        createdDate: x.createdDate,
        updatedDate: x.lastUpdateDate,
        score: 1,
      };
    });
    */

    if (!this.store.getAuth) {
      this.$router.push("/");
    }
  },
  async created() {
    this.loading = true;
    const result = await this.getData();

    this.items = result.data.items;

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      for (let j = 0; j < item.data.submits.length; j++) {
        const _item = item.data.submits[j];

        for (let k = 0; k < 3; k++) {
          this.exportItems.push({
            email: item.data.email,
            createdDate: item.createdDate,
            updatedDate: item.lastUpdateDate,
            document: this.getDocumentName(_item.document),
            type: "C" + (k + 1),
            score: this.getScore(_item, k + 1),
            comment: this.getComment(_item, k + 1),
          });
        }
      }
    }

    /*
    console.warn({
      items: this.items,
      exportItems: this.exportItems,
    });
    */

    this.loading = false;
  },
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },
    async getData() {
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "NGRGFormContoller/GetAll",

        {
          headers: {
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    getDocumentName(id) {
      let item = this.documents.find((item) => item.id === id);
      return item ? item.title : "";
    },
    getScore(item, index) {
      switch (index) {
        case 1:
          return item.c1_score;
        case 2:
          return item.c2_score;
        case 3:
          return item.c3_score;
      }

      return "";
    },
    getComment(item, index) {
      switch (index) {
        case 1:
          return item.c1_comment;
        case 2:
          return item.c2_comment;
        case 3:
          return item.c3_comment;
      }

      return "";
    },
  },
};
