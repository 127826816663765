export default {
  name: "SmartTable",
  props: {
    headers: Array,
    items: Array,
    loading: Boolean,
    documents: Array,
  },
  data() {
    return {
      search: "",
      dialog: false,
      menu: false,
      modal: false,
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
  },
};
